<template>
  <div class="goods" v-if="schoolInfo">
    <van-nav-bar
      :title="schoolInfo.name"
      placeholder
      fixed
      z-index="999"
      style="width: 100%"
    />

    <van-swipe class="goods-swipe" indicator-color="#fff" :autoplay="3000">
      <van-swipe-item v-for="thumb in schoolInfo.images" :key="thumb">
        <img :src="thumb" />
      </van-swipe-item>
    </van-swipe>

    <div class="address" v-for="(item, i) in placeList" :key="i">
      <div class="left-address">
        <div class="address-box">
          <span class="iconfont icon-location"></span>
          <!-- <img class="location-img" src="../../assets/home/location.png" /> -->
          <div class="address-title">{{ item.name }}</div>
        </div>
        <div class="address-detail">{{ item.address }}</div>
      </div>

      <!-- <span class="nav-box" @click="toNav">
        <img class="nav-img" src="../../assets/home/nav.png" />
        <div class="nav-title">导航</div>
      </span> -->
    </div>

    <van-row class="function-enter" type="flex" justify="space-between">
      <img
        class="enter-img"
        v-for="item in schoolInfo.configList"
        :key="item.url"
        :src="item.image"
        @click="toEnterDetail(item.url)"
      />
    </van-row>

    <div class="info-box">
      <van-tabs color="var(--theme-color)" line-width="18px" line-height="4px">
        <van-tab v-for="item in list" :key="item.name" :title="item.title">
          <div class="company-box">
            <template v-if="item.styleType == 1">
              <div
                class="content-box"
                v-for="(item1,i) in item.jsonData"
                :key="item1.title"
                :style="i<item.jsonData.length-1 ? 'border-bottom: 1px solid #e5e5e5;' : 'border-bottom: 0px solid #e5e5e5;'"
              >
                <div class="title-box" v-if="item1.title">
                  <span class="iconfont icon-home_left"></span>
                  <span class="title">{{ item1.title }}</span>
                  <span class="iconfont icon-home_right"></span>
                </div>
                <img
                  class="img"
                  v-for="image in item1.images"
                  :key="image"
                  :src="image"
                />
                <span class="content-text" :style="item1.images.length>0 ? 'margin-top: 12px;' : 'margin-top: 0px;'"> {{ item1.content }} </span>
              </div>
            </template>

            <template v-if="item.styleType == 2">
              <div
                class="content-box1"
                v-for="item1 in item.jsonData"
                :key="item1.title"
              >
                <div class="title-box" v-if="item1.title">
                  <span class="iconfont icon-home_left"></span>
                  <span class="title">{{ item1.title }}</span>
                  <span class="iconfont icon-home_right"></span>
                </div>
                <div
                  class="step-box"
                  v-for="(item2, index) in item1.content"
                  :key="item2"
                >
                  <span class="step-content">{{ item2 }}</span>
                  <span class="step-lead">
                    <span class="radius-box"></span>
                    <span
                      class="bottom-line"
                      v-if="index < item1.content.length - 1"
                    ></span>
                    <span
                      class="bottom-line"
                      v-if="index < item1.content.length - 1"
                    ></span>
                  </span>
                </div>
              </div>
            </template>
            <template v-if="item.styleType == 3">
              <div
                class="content-box2"
                v-for="item1 in item.jsonData"
                :key="item1.title"
              >
                <span class="title-box" v-if="item1.title">
                  <span class="title-lead"></span>
                  <span class="title-big">{{ item1.title }}</span>
                </span>
                <div
                  class="content-text-box"
                  v-for="item2 in item1.sonList"
                  :key="item2.title"
                >
                  <span class="title-small">{{ item2.sonTitle }}</span>
                  <span class="content-text">{{ item2.sonContent }}</span>
                </div>
              </div>
            </template>
          </div>
        </van-tab>
      </van-tabs>
    </div>
    <a class="kf-img-box" :href="'tel:' + kefuPhone">
      <img class="kf-img" src="../../assets/home/kf.png" />
      <!-- <span class="iconfont icon-kf-img"></span>
      <span class="kf-title">咨询</span> -->
    </a>
  </div>
</template>

<script>
import {
  Tag,
  Col,
  Icon,
  Cell,
  CellGroup,
  Swipe,
  SwipeItem,
  GoodsAction,
  GoodsActionIcon,
  GoodsActionButton,
  Row,
  Tab,
  Tabs,
  NavBar,
} from "vant";

import { Home_Api } from "@/api/home";
import { getLocalStorage, setLocalStorage } from "@/core/utils/local-storage";
import { parse } from "qs";

export default {
  components: {
    [Tag.name]: Tag,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [GoodsAction.name]: GoodsAction,
    [GoodsActionIcon.name]: GoodsActionIcon,
    [GoodsActionButton.name]: GoodsActionButton,
    [Row.name]: Row,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [NavBar.name]: NavBar,
    [Icon.name]: Icon,
  },

  data() {
    return {
      activeName: "a",
      placeList: [],
      list: [],
      schoolInfo: {},
      kefuPhone: "",
    };
  },

  mounted() {
    let query = parse(getLocalStorage("query"));

    // this.$store.commit("changeThemeType", 1);

    this.$reqGet(Home_Api, { ...query }).then((res) => {
      this.schoolInfo = res.map.object;
      this.list = res.map.list;
      this.placeList = res.map.placeList;
      this.kefuPhone = res.map.kefuPhone;

      setLocalStorage({
        themeColor: res.map.object.themeColor2,
      });
      this.$store.commit("changeThemeType", res.map.object.themeColor2);
    });
  },

  methods: {
    toEnterDetail(url) {
      this.$router.push({ name: url });
    },
    toNav() {},
  },
};
</script>

<style lang="less">
.company-box {
  width: 100%;
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .content-box2 {
    width: 307px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 12px;
    .title-box {
      display: flex;
      align-items: center;
      width: 100%;
      margin-bottom: 8px;
      .title-lead {
        width: 6px;
        height: 6px;
        border-radius: 3px;
        background-color: var(--theme-color);
      }
      .title-big {
        color: var(--theme-color);
        font-size: 15px;
        font-weight: 500;
        margin-left: 8px;
      }
    }
    .content-text-box {
      width: 293px;
      margin: 0px 0 8px 14px;
      display: flex;
      flex-direction: column;
      .title-small {
        font-size: 14px;
        color: #333333;
        font-weight: 500;
      }
      .content-text {
        width: 100%;
        color: #666666;
        font-size: 14px;
        margin-top: 6px;
        white-space: pre-wrap;
      }
    }
  }
  .content-box1 {
    width: 307px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: 1px solid #e5e5e5;
    padding-bottom: 13px;
    margin-bottom: 12px;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    .title-box {
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 307px;
      height: 44px;
      background-color: #e0e0e0;
      margin-bottom: 17px;
      .title {
        font-size: 16px;
        color: var(--theme-color);
        margin: 0 16px;
      }
      .iconfont {
        font-size: 20px;
      }
    }

    .step-box {
      display: flex;
      position: relative;
      height: 36px;
      width: 279px;

      .step-content {
        background-color: #e0e0e0;
        height: 22px;
        padding-right: 14px;
        padding-left: 20px;
        border-radius: 11px;
        font-size: 14px;
        color: #333333;
        margin-left: 14px;
        margin-top: 3px;
        line-height: 22px;
      }

      .step-lead {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .radius-box {
          width: 22px;
          height: 22px;
          border-radius: 15px;
          border: 3px solid white;
          background-color: #e0e0e0;
        }

        .bottom-line {
          width: 1px;
          height: 4px;
          background-color: #333333;
          margin-bottom: 1px;
        }
      }
    }
  }
  .content-box {
    width: 307px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: 1px solid #e5e5e5;
    padding-bottom: 13px;
    margin-bottom: 12px;
    .title-box {
      display: flex;
      align-items: center;
      width: 307px;
      justify-content: center;
      margin-bottom: 8px;

      .title {
        font-size: 16px;
        color: var(--theme-color);
        margin: 0 16px;
      }
      .iconfont {
        font-size: 20px;
      }
    }
    .img {
      width: 307px;
      // height: 172px;
      object-fit: cover;
    }
    .content-text {
      font-size: 14px;
      color: #333333;
      margin-top: 12px;
      text-align: center;
      white-space: pre-line;
      width: 100%;
    }
  }
  .text {
    width: 307px;
    margin-top: 12px;
    font-size: 14px;
    color: #666666;
    white-space: pre-line;
  }
}
.address-box {
  display: flex;
  align-items: center;
  .iconfont {
    font-size: 16px;
  }
}
.location-img {
  width: 13px;
  height: 16px;
  margin-right: 8px;
}
.van-tabs--line .van-tabs__wrap {
  height: 30px;
}
.van-tab--active {
  color: #333333;
  font-size: 16px;
  font-weight: 500;
}
.van-tab {
  color: #333333;
  font-size: 16px;
}
.van-tabs__nav {
  background-color: transparent;
}

.goods {
  padding-bottom: 50px;
  background-color: white;

  .kf-img-box {
    width: 70px;
    height: 70px;
    border-radius: 35px;
    position: fixed;
    right: 0;
    bottom: 90px;
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    // background-color: var(--apply-header-color);
    // .iconfont {
    //   margin-top: 10px;
    //   font-size: 20px;
    // }
    // .kf-title {
    //   color: var(--theme-color);
    //   font-size: 14px;
    //   margin-top: 3px;
    // }
    .kf-img{
     width: 70px;
     height: 70px;
    }
  }

  &-swipe {
    img {
      width: 100%;
      // display: block;
      height: 237px;
    }
  }

  .info-box {
    margin: 0 auto 40px auto;
    padding: 21px 0px;
    width: 343px;
    // height: 300px;
    background: linear-gradient(
      180deg,
      #f7f7f7 0%,
      rgba(255, 255, 255, 0) 100%
    );
    border-radius: 8px;
    border: 0.5px solid #999999;
  }

  .address {
    margin: 0 auto;
    width: 343px;
    height: 76px;
    box-shadow: 0px 0px 6px 0px rgba(153, 153, 153, 0.38);
    border-radius: 8px;
    // border: 0.5px solid #979797;
    margin-top: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .left-address {
    display: flex;
    flex-direction: column;
    margin-left: 16px;
  }
  .address-title {
    color: #333333;
    font-weight: 500;
    font-size: 16px;
    margin-left: 8px;
  }
  .address-detail {
    margin-top: 6px;
    font-size: 14px;
    color: #666666;
  }
  .nav-box {
    margin-right: 16px;
    display: flex;
    align-items: center;
  }
  .nav-img {
    width: 16px;
    height: 17px;
    margin-right: 6px;
  }
  .nav-title {
    color: var(--theme-color);
    font-size: 16px;
    font-weight: 500;
  }

  &-price {
    color: #f44;
  }

  &-express {
    color: #999;
    font-size: 12px;
    padding: 5px 15px;
  }

  &-cell-group {
    margin: 15px 0;

    .van-cell__value {
      color: #999;
    }
  }

  .function-enter {
    margin: 15px auto;
    width: 343px;
  }
  .enter-img {
    width: 168px;
    height: 96px;
    object-fit: cover;
  }

  .bottom-box {
    margin-bottom: 50px;
  }

  &-tag {
    margin-left: 5px;
  }
}
</style>
